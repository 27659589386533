import {useState, useEffect} from 'react'

const CountdownTimer = (props) => {
  const [active, setActive] = useState(true)
  const [string, setString] = useState()

  useEffect(() => {
    const updateState = () => {
let timeTillCheckinAvailableMins = Math.round((props.targetTimeSecs - (Date.now() / 1000) + props.timestampDifference) / 60)
      if(timeTillCheckinAvailableMins >= 0) {
        setString(timeTillCheckinAvailableMins > 60
          ? `${Math.floor(timeTillCheckinAvailableMins / 60)} hours ${timeTillCheckinAvailableMins % 60} minutes`
          : timeTillCheckinAvailableMins === 0
          ? `a few seconds`
          : `${timeTillCheckinAvailableMins} minutes`)
      } else {
        setActive(false)
      }
    }
    updateState()
    if(active) {
      const interval = setInterval(() => {
        updateState()
      }, props.refreshPeriodSecs * 1000)
      return () => clearInterval(interval);
    }
  }, [active, props.refreshPeriodSecs, props.targetTimeSecs, props.currentTimestamp, props.timestampDifference])

  return string ? string : null

}

export default CountdownTimer


